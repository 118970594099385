import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "reports-menu" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("h1", { staticClass: "header-title px-5 pb-3" }, [_vm._v("Container Filling Reports")]), _c("div", { staticClass: "p-5" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "lg": 24, "xs": 24 } }, [_c("a-card", { staticClass: "reports-card" }, [_c("h3", { staticClass: "section-title mb-4" }, [_vm._v("Preview Container Filling Reports")]), _c("a-row", { attrs: { "gutter": 24 } }, _vm._l(_vm.reports, function(report) {
    return _c("a-col", { key: report.name, staticClass: "mb-4", attrs: { "xl": 12, "xs": 24 } }, [_c("a-card", [_c("a-row", { staticClass: "row-card-preview", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("a-icon", { style: _vm.icon.style, attrs: { "type": report.icon, "theme": _vm.icon.theme } })], 1), _c("a-col", { attrs: { "span": 9 } }, [_c("span", [_vm._v(_vm._s(report.title))])]), _c("a-col", { attrs: { "span": 9 } }, [_c("a-button", { on: { "click": function($event) {
      return _vm.preview(report.name);
    } } }, [_vm._v("Preview")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var ReportsMenu_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ReportsMenu",
  data() {
    return {
      itemsMenu: [
        {
          key: "reports",
          title: "Reports",
          path: ""
        },
        {
          key: "auditreports",
          title: "Container Filling Reports",
          path: "/container-filling/reports"
        }
      ],
      icon: {
        theme: "twoTone",
        style: {
          fontSize: "21px",
          color: "#0062FF"
        }
      },
      reports: [
        {
          name: "CFProduction",
          title: "Container Production",
          icon: "file-text"
        },
        {
          name: "CFProductionHourly",
          title: "Container Production Hourly",
          icon: "clock-circle"
        },
        {
          name: "CFSampleProduction",
          title: "Samples Production",
          icon: "check-circle"
        },
        {
          name: "CFNotInterfaced",
          title: "Containers Not Yet Interfaced",
          icon: "file-unknown"
        },
        {
          name: "CFSampleOnSuspend",
          title: "Samples on Suspend",
          icon: "pause-circle"
        }
      ]
    };
  },
  methods: {
    preview(type) {
      const data = {
        report: type,
        reportType: "container-filling",
        reportParams: {}
      };
      this.axios.post(apiUrl$1 + `/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "78498884", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportsMenu = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "reports-menu" }, [_c("resource", { attrs: { "name": "cf.common.warehouses", "api-url": _vm.apiUrl, "resource-id-name": "warehouse" } }), _c("resource", { attrs: { "name": "cf.common.prod-line", "api-url": _vm.apiUrl, "resource-id-name": "code" } }, [_c("reports-menu")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ReportsMenu
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
